import $ from 'jquery';
import Widgets from 'js-widget-hooks';
import 'bootstrap/js/transition';
import 'bootstrap/js/collapse';

Widgets.register('glossary', function(elem){

    var el = $(elem);

    // a bit of custom code, to allow the closing in multiple collumns
    el.find('.collapse').on('show.bs.collapse', function () {
        el.find('.collapse.in').collapse('hide');
    })
});